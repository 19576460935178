.App {
  text-align: center;
  background-color: hsl(240, 64%, 11%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-image img {
  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 40px 0 0 0;
}

main {
}

.main-container {
  z-index: 99;
}

label {
  color: white;
}

image {
  width: 100%;
  height: 100%;
}

.background {
  width: 100%;
  position: relative;
}

.background-image {
  background-color: #0a0a2d;

}

.astronaut-image {
  position: absolute;
  right: 0;
  bottom: 0;
}
.astronaut-image img {
  width: clamp(200px, 54.45vw, 942px);
}

.background-image img {
  width: 100%;
}